import React from "react"

import Layout from "../components/layout"
import Splash from "../components/landing/splash"
import Foci from "../components/landing/foci"

const IndexPage = () => {
  return (
    <Layout>
      <Splash />
      <Foci />
      {/* Three rows of information */}
      {/* Why hack? */}
    </Layout>
  )
}

export default IndexPage
