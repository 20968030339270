import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"

import splashImg from "../../images/landingImg.svg"

const Splash = () => {
  return (
    <SplashWrapper>
      <motion.div
        className="splash-info"
        initial={{ opacity: 0, x: -400, y: -150 }}
        animate={{ opacity: 1, x: 0, y: 0 }}
        transition={{ duration: 1 }}
      >
        <h1 className="lead">
          The <span>best educational resources</span> to hack your classes
        </h1>
        <p>
          Blog posts, tutorials, and classroom resources: all in one place for
          you to start using with your classes today.
        </p>
        {/* <p style={{ fontSize: `1em`, color: `black` }}>
          Want to stay in the loop about our newest content? Signing up is easy.
        </p>
        <div className="sign-up">
          <input
            type="text"
            placeholder="teacher@yourschool.edu"
            value=""
          ></input>
          <SignUpButton>Get Started</SignUpButton>
        </div> */}
      </motion.div>
      <motion.img
        src={splashImg}
        alt="Cool guy touching stuff"
        initial={{ opacity: 0, x: 400, y: 150 }}
        animate={{ opacity: 1, x: 0, y: 0 }}
        transition={{ duration: 1 }}
      />
    </SplashWrapper>
  )
}

// Styled components:
const SplashWrapper = styled(motion.div)`
  display: grid;
  min-height: 100vh;
  place-items: center center;
  grid-template-columns: 1fr 1fr;
  margin-left: 50px;
  margin-right: 50px;
  padding-left: 30px;
  grid-gap: 10px;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    margin-top: 150px;
  }

  .lead {
    font-size: 2.5em;
    font-family: acumin-pro, sans-serif;
    font-weight: 100;
    font-style: normal;
    margin: 0;
    line-height: 1.15;
  }

  span {
    color: var(--teal);
  }

  .splash-info {
    display: grid;
    min-width: 100%;

    p {
      color: var(--grey-200);
      font-size: 24px;
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  .sign-up {
    display: flex;
    margin-top: 20px;

    input {
      min-width: 50%;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border: 1px solid var(--grey);
      padding: 5px;
      display: block;
      min-height: 40px;
      font-size: 18px;
    }

    button:hover {
      cursor: pointer;
      background: var(--teal);
      border: 1px solid var(--teal);
    }
  }
`
const SignUpButton = styled.button`
  border: 1px solid var(--teal-200);
  background: var(--teal-200);
  color: var(--white);
  margin-top: 0;
  margin-bottom: 0;
  padding: 20px 32px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-left: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  display: block;
  min-height: 40px;
  font-size: 18px;
  transition: ease-in-out 0.3s;
`

export default Splash
