import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { motion } from "framer-motion"

import blogging from "../../images/blogging.svg"
import eduHeader from "../../images/eduHeader.svg"

const Foci = () => {
  return (
    <FociSection>
      <FociWrapper>
        <FociCard>
          <LeadText>Teaching Resources</LeadText>
          <img src={eduHeader} alt="Teacher" />
          <FociDetails>
            <p>
              A curated catalogue of ready-to-use resources in your classroom.
              From graphic organizers, to our favorite services: this is the
              go-to list.
            </p>
            <FociButton to="/resources">Browse</FociButton>
          </FociDetails>
        </FociCard>
        <FociCard>
          <LeadText>Education Blog</LeadText>
          <img src={blogging} alt="Blogger" />
          <FociDetails>
            <p>
              Pedagogy, technology, CS...we publish new posts weekly and work to
              create content that helps you and your students.
            </p>
            <FociButton to="/blog">Read</FociButton>
          </FociDetails>
        </FociCard>
      </FociWrapper>
    </FociSection>
  )
}

// Styled components:
const FociSection = styled.section`
  background: var(--grey);
  margin-top: 0;
`

const FociWrapper = styled(motion.div)`
  margin-left: 200px;
  margin-right: 200px;
  padding-top: 200px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;

  @media (max-width: 700px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`

const FociCard = styled.div`
  display: grid;
  justify-self: center;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 150px;
  max-width: 700px;

  img {
    width: 100%;
    height: auto;
  }
`

const LeadText = styled.p`
  font-size: 2.5em;
  font-family: acumin-pro, sans-serif;
  font-weight: 100;
  font-style: normal;
  margin: 0;
  grid-column: 1/-1;
`

const FociDetails = styled.div`
  display: grid;
`

const FociButton = styled(Link)`
  background: var(--teal-200);
  border: 1px solid var(--teal-200);
  color: var(--white);
  margin-left: 20px;
  padding: 20px 32px;
  justify-self: right;
  background: var(--teal-200);
  border: 1px solid var(--teal-200);
  color: var(--white);
  padding: 20px 32px;
  align-self: end;
  border-radius: 8px;
  text-decoration: none;
  transition: ease-in-out 0.3s;

  :hover {
    background: var(--teal);
    border: 1px solid var(--teal);
  }
`

export default Foci
